<template>
  <GuillaimeP5 />
  <MinhP5 />
</template>

<script>
import GuillaimeP5 from "@/components/P5.Guillaime.vue"
import MinhP5 from "@/components/P5.Minh.vue"

export default {
  components: { GuillaimeP5, MinhP5 },
}
</script>
